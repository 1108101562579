import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Divider } from '@/components/ui/Divider';
import { SmallMap } from '@/components/maps/SmallMap';

export default function SostaInStrutturaASbarra() {
  const pageTitle = 'Sosta in struttura e a sbarra';

  const Banner = () => (
    <StaticImage
      src="../images/banners/sosta-in-struttura-e-a-sbarra.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => <SmallMap />;

  const Right = () => (
    <div>
      <div className="mb-3">
        <div>
          <h2 className="h3 text-primary font-rubik">
            Indicazione planimetrica delle zone di sosta
          </h2>
          <div className="mt-4 mb-5">
            <div className="mb-4">
              <h3 className="text-uppercase h4 font-rubik text-primary mb-1">
                Parcheggi in struttura
              </h3>
              <p className="text-secondary m-0">
                <b>Strasburgo</b> - 131 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Napoli</b> - 27 posti auto (non attivo)
              </p>
              <p className="text-secondary m-0">
                <b>Trissino</b> - 30 posti auto (non attivo)
              </p>
            </div>
            <div>
              <h3 className="text-uppercase h4 font-rubik text-primary mb-1">
                Parcheggi a sbarra
              </h3>
              <p className="text-secondary m-0">
                <b>F.lli Bandiera</b> - 228 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Fogazzaro</b> - 405 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Canove</b> - 201 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Matteotti</b> - 46 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Cattaneo</b> - 245 posti auto
              </p>
              <p className="text-secondary m-0">
                <b>Bologna</b> - 65 posti auto
              </p>
            </div>
          </div>
        </div>
        <div>
          <Link to={'/modulistica'}>
            <button className="btn btn-primary mb-3">
              Consulta i regolamenti di sosta
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  const Center = () => (
    <div>
      <div className="text-center">
        <h2 className="font-rubik text-primary h3 mb-5">
          Consulta le nostre tariffe di sosta e abbonamento per i parcheggi a
          sbarra e in struttura
        </h2>
        <div className="mb-5">
          <div className="row gx-3">
            <div className="d-none d-lg-block col-3" />
            <div className="col-12 col-lg-12">
              <a
                href="/files/tariffe-e-orari/ALLEGATO_ TARIFFE PARCHEGGI INTERSCAMBIO.pdf"
                target="_blank"
              >
                <button
                  style={{ minWidth: '256px' }}
                  className="btn btn-primary mb-3"
                >
                  Tariffe parcheggi a sbarra
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
        <Divider size={5} />
        <Center />
      </div>
    </Layout>
  );
}
